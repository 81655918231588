import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ability from "@/libs/acl/ability";

export default function useDocumentsList() {
  // Use toast
  const toast = useToast();

  const refDocumentListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {
      key: "category_name",
      stickyColumn: true,
      label: "Category",
      // sortable: true,
    },
    // { key: "file_name", label: "File Name", sortable: true },
    { key: "document_type_name", label: "Document type", sortable: true },
    { key: "original_name", label: "File Name", sortable: true },
    // { key: "user.name", label: "Uploaded by" },
    { key: "file_date", label: "File Date", sortable: true },
    { key: "authorised_users", label: "Authorised User" },
    { key: "actions" },
  ];

  const tableClientColumns = [
    {
      key: "name_en",
      label: "Client",
      stickyColumn: true,
      sortable: true,
    },
    { key: "category_name", label: "Category" },
    // { key: "file_name", label: "File Name", sortable: true },
    { key: "original_name", label: "File Name", sortable: true },
    // { key: "user.name", label: "Uploaded by" },
    { key: "client_bank.bank.name", label: "Affiliated Bank" },
    { key: "file_date", label: "File Date", sortable: true },
    { key: "authorised_users", label: "Authorised Staff" },
    { key: "actions" },
  ];

  // Table Handlers
  const tableColumnsRM = [
    {
      key: "category_name",
      stickyColumn: true,
      label: "Category",
      sortable: true,
    },
    // { key: "file_name", label: "File Name", sortable: true },
    { key: "document_type_name", label: "Document type", sortable: true },
    { key: "original_name", label: "File Name", sortable: true },
    // { key: "user.name", label: "Uploaded by" },
    { key: "file_date", label: "File Date", sortable: true },
    { key: "authorised_users", label: "Authorised User" },
    { key: "actions" },
  ];

  const tableClientColumnsRM = [
    {
      key: "name_en",
      label: "Client",
      stickyColumn: true,
      sortable: true,
    },
    { key: "category_name", label: "Category", sortable: true },
    // { key: "file_name", label: "File Name", sortable: true },
    { key: "original_name", label: "File Name", sortable: true },
    // { key: "user.name", label: "Uploaded by" },
    { key: "client_bank.bank.name", label: "Affiliated Bank" },
    { key: "file_date", label: "File Date", sortable: true },
    { key: "authorised_users", label: "Authorised User" },
    { key: "actions" },
  ];

  const perPage = ref(10);
  const totalDocuments = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  //Staff or Client filter
  const isClientFilter = ref(0);
  const userFilter = ref(null);
  const uploadUserFilter = ref(null);
  const dateFromFilter = ref(null);
  const dateToFilter = ref(null);
  const bankFilter = ref(null);
  const clientIdFilter = ref(null);
  const documentCategoryIdFilter = ref(null);
  const documentTypeIdFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refDocumentListTable.value
      ? refDocumentListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDocuments.value,
    };
  });

  const refetchData = () => {
    refDocumentListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      isClientFilter,
      userFilter,
      uploadUserFilter,
      dateFromFilter,
      dateToFilter,
      bankFilter,
      clientIdFilter,
      documentCategoryIdFilter,
      documentTypeIdFilter,
    ],
    () => {
      refetchData();
    }
  );

  const fetchDocuments = (ctx, callback) => {
    store
      .dispatch("app-document/fetchDocuments", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        is_client: isClientFilter.value,
        user_id: uploadUserFilter.value,
        authorised_user_id: userFilter.value,
        bank_id: bankFilter.value,
        client_id: clientIdFilter.value,
        document_category_id: documentCategoryIdFilter.value,
        document_type_id: documentTypeIdFilter.value,
        date_from: dateFromFilter.value,
        date_to: dateToFilter.value,
      })
      .then((response) => {
        const { documents, total } = response.data;
        callback(documents);
        totalDocuments.value = total;
      })
      .catch((err) => {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching document list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    fetchDocuments,
    tableColumns,
    perPage,
    currentPage,
    totalDocuments,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDocumentListTable,
    ability,
    refetchData,
    isClientFilter,
    tableClientColumns,
    tableColumnsRM,
    tableClientColumnsRM,
    userFilter,
    uploadUserFilter,
    dateFromFilter,
    dateToFilter,
    bankFilter,
    clientIdFilter,
    documentCategoryIdFilter,
    documentTypeIdFilter,
  };
}
