<template>
  <b-modal
    id="modal-user-filter"
    ok-title="Submit"
    cancel-variant="outline-secondary"
    scrollable
    title="FILTER"
    title-class="font-weight-bolder"
    body-class="common_modal modal_visible"
    hide-footer
    no-close-on-backdrop
    size="lg"
    hide-header-close
  >
    <feather-icon
      @click="hide"
      style="position: absolute; right: 20px; top: -40px"
      size="18"
      icon="XIcon"
    />
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-form class="mt-1" @submit.prevent="onSubmit">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Authorised User"
              label-for="authorised_users"
              label-cols-md="3"
            >
              <div class="form-col-select">
                <v-select
                  id="authorised_users"
                  :value="update.userFilter"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="users"
                  label="name"
                  :reduce="(option) => option.id"
                  multiple
                  placeholder="Select Authorised User"
                  @input="(val) => (update.userFilter = val)"
                />
                <feather-icon size="18" icon="ChevronDownIcon" />
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" v-if="isClientFilter">
            <b-form-group
              label="Client"
              label-for="client_id"
              label-cols-md="3"
            >
              <div class="form-col-select">
                <v-select
                  id="client_id"
                  :value="update.clientIdFilter"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="clients"
                  label="name_en"
                  :reduce="(option) => option.id"
                  placeholder="Select Client"
                  @input="(val) => (update.clientIdFilter = val)"
                />
                <feather-icon size="18" icon="ChevronDownIcon" />
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Document Category"
              label-for="document_category_id"
              label-cols-md="3"
            >
              <div class="form-col-select">
                <v-select
                  id="document_category_id"
                  :value="update.documentCategoryIdFilter"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="documentCategories"
                  label="category_name"
                  :reduce="(option) => option.id"
                  placeholder="Select Document Category"
                  @input="(val) => (update.documentCategoryIdFilter = val)"
                />
                <feather-icon size="18" icon="ChevronDownIcon" />
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" v-if="!isClientFilter">
            <b-form-group
              label="Document Type"
              label-for="document_type_id"
              label-cols-md="3"
            >
              <div class="form-col-select">
                <v-select
                  id="document_type_id"
                  :value="update.documentTypeIdFilter"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="documentTypes"
                  label="type"
                  :reduce="(option) => option.id"
                  placeholder="Select Document Type"
                  @input="(val) => (update.documentTypeIdFilter = val)"
                />
                <feather-icon size="18" icon="ChevronDownIcon" />
              </div>
            </b-form-group>
          </b-col>
          <!-- <b-col cols="12">
            <b-form-group label="Upload by:" label-for="upload_by" label-cols-md="3">
              <div class="form-col-select">
                <v-select
                  id="upload_by"
                  :value="update.uploadUserFilter"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="users"
                  label="name"
                  :reduce="(option) => option.id"
                  multiple
                  placeholder="Select User"
                  @input="(val) => (update.uploadUserFilter = val)"
                />
                <feather-icon size="18" icon="ChevronDownIcon" />
              </div>
            </b-form-group>
          </b-col> -->
          <b-col cols="12">
            <b-form-group
              label="Date From:"
              label-for="fliter_date_from"
              label-cols-md="3"
            >
              <flat-pickr
                id="fliter_date_from"
                :value="update.dateFromFilter"
                class="form-control"
                :config="flatDateConfig"
                placeholder="YYYY-MM-DD"
                @input="(val) => (update.dateFromFilter = val)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Date To:"
              label-for="fliter_date_to"
              label-cols-md="3"
            >
              <flat-pickr
                id="fliter_date_to"
                :value="update.dateToFilter"
                class="form-control"
                :config="flatDateConfig"
                placeholder="YYYY-MM-DD"
                @input="(val) => (update.dateToFilter = val)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" v-if="isClientFilter">
            <b-form-group
              label="Bank:"
              label-for="fliter_bank"
              label-cols-md="3"
            >
              <div class="form-col-select">
                <v-select
                  id="fliter_bank"
                  :value="update.bankFilter"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="banks"
                  :clearable="false"
                  label="name"
                  :reduce="(option) => option.id"
                  multiple
                  placeholder="Select Bank"
                  @input="(val) => (update.bankFilter = val)"
                />
                <feather-icon size="18" icon="ChevronDownIcon" />
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="d-flex mt-2 justify-content-end">
          <!-- type="reset" -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="update = {}"
            class="mr-1"
          >
            Reset
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-danger"
            class="mr-1"
            @click="hide"
          >
            Cancel
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
          >
            <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
            Submit
          </b-button>
        </div>
      </b-form>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCardText,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardBody,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BButton,
    BModal,
    VBModal,
    BCardText,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCardBody,
    BCard,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  methods: {
    hide() {
      this.$bvModal.hide("modal-user-filter");
    },
    onSubmit() {
      this.$emit("update:userFilter", this.update.userFilter);
      this.$emit("update:uploadUserFilter", this.update.uploadUserFilter);
      this.$emit("update:dateFromFilter", this.update.dateFromFilter);
      this.$emit("update:dateToFilter", this.update.dateToFilter);
      this.$emit("update:bankFilter", this.update.bankFilter);
      this.$emit("update:clientIdFilter", this.update.clientIdFilter);
      this.$emit(
        "update:documentCategoryIdFilter",
        this.update.documentCategoryIdFilter
      );
      this.$emit(
        "update:documentTypeIdFilter",
        this.update.documentTypeIdFilter
      );
      // this.$emit("update:clients", this.update.clients);
      this.hide();
    },
  },
  props: [
    "users",
    "clients",
    "documentCategories",
    "documentTypes",
    "banks",
    "userFilter",
    "uploadUserFilter",
    "bankFilter",
    "clientIdFilter",
    "documentCategoryIdFilter",
    "documentTypeIdFilter",
    "dateFromFilter",
    "dateToFilter",
    "isClientFilter",
  ],
  data() {
    return {
      loading: false,
      update: {
        userFilter: "",
        uploadUserFilter: "",
        dateFromFilter: "",
        dateToFilter: "",
        bankFilter: "",
        clientIdFilter: "",
        documentCategoryIdFilter: "",
        documentTypeIdFilter: "",
        // clients: "",
      },
      filter: {
        user_id: null,
        bank_id: null,
        client_id: null,
        document_category_id: null,
        document_type_id: null,
      },
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
