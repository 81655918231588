<template>
  <div>
    <modal-user-filter
      :users="users"
      :clients="clients"
      :document-categories="documentCategories"
      :document-types="documentTypes"
      :banks="bankOptions"
      :user-filter.sync="userFilter"
      :upload-user-filter.sync="uploadUserFilter"
      :date-from-filter.sync="dateFromFilter"
      :date-to-filter.sync="dateToFilter"
      :bank-filter.sync="bankFilter"
      :client-id-filter.sync="clientIdFilter"
      :document-category-id-filter.sync="documentCategoryIdFilter"
      :document-type-id-filter.sync="documentTypeIdFilter"
      :is-client-filter="isClientFilter"
    />

    <modal-user-filter-r-m
      :users="users"
      :clients="clients"
      :document-categories="documentCategories"
      :document-types="documentTypes"
      :banks="bankOptions"
      :user-filter.sync="userFilter"
      :upload-user-filter.sync="uploadUserFilter"
      :date-from-filter.sync="dateFromFilter"
      :date-to-filter.sync="dateToFilter"
      :bank-filter.sync="bankFilter"
      :client-id-filter.sync="clientIdFilter"
      :document-category-id-filter.sync="documentCategoryIdFilter"
      :document-type-id-filter.sync="documentTypeIdFilter"
      :is-client-filter="isClientFilter"
    />

    <modal-user
      :document-data.sync="documentData"
      :user-options="userOptions"
      :trigger="trigger"
      @refetch-data="refetchData"
    />

    <modal-email :emailDocument="emailDocument" />

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <div class="d-flex flex-wrap align-items-center justify-content-between">
          <div class="d-flex flex-wrap align-items-center">
            <h3 class="font-weight-bolder text-nowrap mb-0">DOCUMENTS</h3>
            <div class="form-col-select ml-2">
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <feather-icon size="18" icon="ChevronDownIcon" />
            </div>
          </div>
          <div class="d-flex detail-top-b-list flex-wrap justify-content-end">
            <b-button
              class="mobile-w100 px-3"
              variant="outline-primary"
              @click="$bvModal.show('modal-user-filter')"
              v-if="ability.can('update', 'documents')"
            >
              <feather-icon icon="FilterIcon" size="16" class="mr-1" />Fliter</b-button
            >
            <b-button
              class="mobile-w100 px-3"
              variant="outline-primary"
              @click="$bvModal.show('modal-user-filter-r-m')"
              v-else
            >
              <feather-icon icon="FilterIcon" size="16" class="mr-1" />Fliter</b-button
            >
            <template v-if="isClientFilter">
              <b-button
                v-if="ability.can('create', 'document_client')"
                class="mobile-w100"
                variant="primary"
                :to="{ name: 'document-client-upload' }"
              >
                <span class="text-nowrap"
                  ><feather-icon icon="PlusIcon" size="16" class="mr-1" />Upload
                  File</span
                >
              </b-button>
            </template>
            <template v-else>
              <b-button
                v-if="ability.can('create', 'document_user')"
                class="mobile-w100"
                variant="primary"
                :to="{ name: 'document-staff-upload' }"
              >
                <span class="text-nowrap"
                  ><feather-icon icon="PlusIcon" size="16" class="mr-1" />Upload
                  File</span
                >
              </b-button>
            </template>
          </div>
        </div>
      </div>

      <div v-if="ability.can('update', 'document')">
        <b-table
          ref="refDocumentListTable"
          class="position-relative"
          :items="fetchDocuments"
          responsive
          :fields="isClientFilter ? tableClientColumns : tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- <template #cell(name_cn)="data">
          <div v-if="data.item.name_cn == null">-</div>
          <div v-else>{{ data.item.name_cn }}</div>
        </template> -->

          <!-- Column: Actions -->
          <template #cell(authorised_users)="data">
            <b-row style="min-width: 180px">
              <b-col cols="9">
                <div v-if="data.item.all_bit">
                  <b-badge class="user-badge d-block">ALL</b-badge>
                </div>
                <div v-else>
                  <b-badge
                    class="user-badge d-block"
                    v-for="(user, index) in data.item.authorised_users"
                    :key="index"
                  >
                    {{ user.name }}
                  </b-badge>
                </div>
              </b-col>
              <b-col
                cols="2"
                class="edit-icon d-flex align-items-center justify-content-start"
              >
                <div v-if="isClientFilter">
                  <font-awesome-icon
                    v-if="ability.can('update', 'document_client')"
                    icon="fa-solid fa-pen"
                    class="color3"
                    @click="handleChangeUser(data.item)"
                  />
                </div>
                <div v-else>
                  <font-awesome-icon
                    v-if="ability.can('update', 'document_user')"
                    icon="fa-solid fa-pen"
                    class="color3"
                    @click="handleChangeUser(data.item)"
                  />
                </div>
              </b-col>
            </b-row>
          </template>
          <template #cell(actions)="data">
            <div class="d-flex align-items-center">
              <template v-if="isClientFilter">
                <a
                  v-if="ability.can('update', 'documents')"
                  href="javascript:void(0)"
                  @click="handleClientEdit(data.item)"
              >
                  <feather-icon icon="EditIcon" size="16" class="align-middle text-body" />
                </a>
              </template>
              <template v-else>
                <a
                  v-if="ability.can('update', 'documents')"
                  href="javascript:void(0)"
                  @click="handleStaffEdit(data.item)"
                >
                  <feather-icon icon="EditIcon" size="16" class="align-middle text-body" />
                </a>
              </template>
              <span
                class="mx-1"
                style="background: #000; width: 1px; height: 20px"
              ></span>
              <a
                v-if="ability.can('read', 'email')"
                href="javascript:void(0)"
                @click="handleSendEmail(data.item)"
              >
                <feather-icon icon="MailIcon" size="16" class="align-middle text-body" />
              </a>
              <span
                class="mx-1"
                style="background: #000; width: 1px; height: 20px"
              ></span>
              <a href="javascript:void(0)" download @click="handleDownload(data.item)">
                <feather-icon
                  icon="DownloadIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </a>
              <template v-if="isClientFilter">
                <span
                  v-if="ability.can('delete', 'document_client')"
                  class="mx-1"
                  style="background: #000; width: 1px; height: 20px"
                ></span>
                <a
                  href="javascript:void(0)"
                  v-if="ability.can('delete', 'document_client')"
                  @click="handleDelete(data.item.id)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    size="16"
                    class="align-middle text-body"
                  />
                </a>
              </template>
              <template v-else>
                <span
                  v-if="ability.can('delete', 'document_user')"
                  class="mx-1"
                  style="background: #000; width: 1px; height: 20px"
                ></span>
                <a
                  href="javascript:void(0)"
                  v-if="ability.can('delete', 'document_user')"
                  @click="handleDelete(data.item.id)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    size="16"
                    class="align-middle text-body"
                  />
                </a>
              </template>
            </div>
          </template>
        </b-table>
      </div>
      <div v-else>
        <b-table
          ref="refDocumentListTable"
          class="position-relative"
          :items="fetchDocuments"
          responsive
          :fields="isClientFilter ? tableClientColumnsRM : tableColumnsRM"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- <template #cell(name_cn)="data">
          <div v-if="data.item.name_cn == null">-</div>
          <div v-else>{{ data.item.name_cn }}</div>
        </template> -->

          <!-- Column: Actions -->
          <template #cell(authorised_users)="data">
            <b-row style="min-width: 180px">
              <!-- <b-col cols="9">
                <div v-if="data.item.all_bit">
                  <b-badge class="user-badge d-block">ALL</b-badge>
                </div>
                <div v-else>
                  <b-badge
                    class="user-badge d-block"
                    v-for="(user, index) in data.item.authorised_users"
                    :key="index"
                  >
                    {{ user.name }}
                  </b-badge>
                </div>
              </b-col> -->
              <b-col
                cols="2"
                class="edit-icon d-flex align-items-center justify-content-start"
              >
                <div v-if="isClientFilter">
                  <font-awesome-icon
                    v-if="ability.can('update', 'document_client')"
                    icon="fa-solid fa-pen"
                    class="color3"
                    @click="handleChangeUser(data.item)"
                  />
                </div>
                <div v-else>
                  <font-awesome-icon
                    v-if="ability.can('update', 'document_user')"
                    icon="fa-solid fa-pen"
                    class="color3"
                    @click="handleChangeUser(data.item)"
                  />
                </div>
              </b-col>
            </b-row>
          </template>
          <template #cell(actions)="data">
            <div class="d-flex align-items-center">
              <template v-if="isClientFilter">
                <a
                  v-if="ability.can('update', 'documents')"
                  href="javascript:void(0)"
                  @click="handleClientEdit(data.item)"
              >
                  <feather-icon icon="EditIcon" size="16" class="align-middle text-body" />
                </a>
              </template>
              <template v-else>
                <a
                  v-if="ability.can('update', 'documents')"
                  href="javascript:void(0)"
                  @click="handleStaffEdit(data.item)"
                >
                  <feather-icon icon="EditIcon" size="16" class="align-middle text-body" />
                </a>
              </template>
              <span
                class="mx-1"
                style="background: #000; width: 1px; height: 20px"
              ></span>
              <a
                v-if="ability.can('read', 'email')"
                href="javascript:void(0)"
                @click="handleSendEmail(data.item)"
              >
                <feather-icon icon="MailIcon" size="16" class="align-middle text-body" />
              </a>
              <span
                class="mx-1"
                style="background: #000; width: 1px; height: 20px"
              ></span>
              <a href="javascript:void(0)" download @click="handleDownload(data.item)">
                <feather-icon
                  icon="DownloadIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </a>
              <template v-if="isClientFilter">
                <span
                  v-if="ability.can('delete', 'document_client')"
                  class="mx-1"
                  style="background: #000; width: 1px; height: 20px"
                ></span>
                <a
                  href="javascript:void(0)"
                  v-if="ability.can('delete', 'document_client')"
                  @click="handleDelete(data.item.id)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    size="16"
                    class="align-middle text-body"
                  />
                </a>
              </template>
              <template v-else>
                <span
                  v-if="ability.can('delete', 'document_user')"
                  class="mx-1"
                  style="background: #000; width: 1px; height: 20px"
                ></span>
                <a
                  href="javascript:void(0)"
                  v-if="ability.can('delete', 'document_user')"
                  @click="handleDelete(data.item.id)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    size="16"
                    class="align-middle text-body"
                  />
                </a>
              </template>
            </div>
          </template>
        </b-table>
      </div>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalDocuments"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import useDocumentsList from "./useDocumentsList";
import documentStoreModule from "../documentStoreModule";
import router from "@/router";
import ModalUser from "../modal/ModalUser.vue";
import ModalUserFilter from "../modal/ModalUserFilter.vue";
import ModalUserFilterRM from "../modal/ModalUserFilterRM.vue";
import ModalEmail from "../modal/ModalEmail.vue";
import axiosIns from "@/libs/axios";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ModalUser,
    ModalUserFilter,
    ModalUserFilterRM,
    ModalEmail,
  },
  methods: {},
  data() {
    return {
      documentData: {},
      emailDocument: {},
      trigger: false,
    };
  },
  watch: {
    $route(to, from) {
      if (to.name == "document-client-list") {
        this.isClientFilter = 1;
      } else if (to.name == "document-staff-list") {
        this.isClientFilter = 0;
      }
    },
  },
  methods: {
    handleSendEmail(item) {
      this.emailDocument = item;
      this.$bvModal.show("modal-send-email");
    },
    handleDownload(item) {
      //this.$store.dispatch(this.DOCUMENT_APP_STORE_MODULE_NAME+'/fetchDocument', {id})
      axiosIns
        .get("/document", { params: { id: item.id }, responseType: "blob" })
        .then((response) => {
          // alert(response.data)
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);
          // create "a" HTLM element with href to file & click
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", item.original_name); //or any other extension
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        })
        .catch(async (error) => {
          this.loading = false;
          this.$swal({
            text: JSON.stringify(await error.response.statusText),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
    handleDelete(id) {
      this.$swal({
        title: "Are you sure?",
        text: "Your action is final and you will not be able to retrieve the document.",
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("app-document/deleteDocument", { id: id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    handleChangeUser(item) {
      this.documentData = { ...item };

      if (this.documentData.all_bit) {
        this.documentData.authorised_users = [0];
      }

      this.trigger = !this.trigger;

      this.$bvModal.show("modal-user");
    },
    handleStaffEdit(item) {
      this.$router.replace({ 
        name: 'document-staff-edit',
        params: { id: item.id }
      });
    },
    handleClientEdit(item) {
      this.$router.replace({
        name: "document-client-edit",
        params: { id: item.id }
      });
    }
  },
  setup() {
    const DOCUMENT_APP_STORE_MODULE_NAME = "app-document";

    // Register module
    if (!store.hasModule(DOCUMENT_APP_STORE_MODULE_NAME))
      store.registerModule(DOCUMENT_APP_STORE_MODULE_NAME, documentStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DOCUMENT_APP_STORE_MODULE_NAME))
        store.unregisterModule(DOCUMENT_APP_STORE_MODULE_NAME);
    });

    onMounted(() => {
      console.log("router", router.currentRoute.name);
      if (router.currentRoute.name == "document-client-list") {
        isClientFilter.value = 1;
      } else {
        isClientFilter.value = 0;
      }
      refetchOption();
    });

    const userOptions = ref([]);
    const bankOptions = ref([]);
    const users = ref([]);
    const clients = ref([]);
    const documentCategories = ref([]);
    const documentTypes = ref([]);

    const refetchOption = () => {
      store
        .dispatch("app/fetchOptionList", {
          user: true,
          bank: true,
          client: true,
          document_category: true,
          document_type: true,
        })
        .then((response) => {
          users.value = response.data.users;
          clients.value = response.data.clients;
          documentCategories.value = response.data.document_categories;
          documentTypes.value = response.data.document_types;
          bankOptions.value = response.data.banks;
          userOptions.value = response.data.users;
          userOptions.value = [{ id: 0, name: "ALL" }, ...userOptions.value];
        });
    };

    const {
      fetchDocuments,
      tableColumns,
      tableColumnsRM,
      perPage,
      currentPage,
      totalDocuments,
      tableClientColumns,
      tableClientColumnsRM,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDocumentListTable,
      refetchData,
      isClientFilter,
      ability,
      userFilter,
      uploadUserFilter,
      dateFromFilter,
      dateToFilter,
      bankFilter,
      clientIdFilter,
      documentCategoryIdFilter,
      documentTypeIdFilter,
    } = useDocumentsList();

    return {
      // Sidebar
      fetchDocuments,
      tableColumns,
      perPage,
      currentPage,
      totalDocuments,
      tableClientColumns,
      tableColumnsRM,
      tableClientColumnsRM,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDocumentListTable,
      refetchData,
      ability,
      isClientFilter,
      userOptions,
      users,
      clients,
      documentCategories,
      documentTypes,
      userFilter,
      uploadUserFilter,
      bankOptions,
      dateFromFilter,
      dateToFilter,
      bankFilter,
      clientIdFilter,
      documentCategoryIdFilter,
      documentTypeIdFilter,
      DOCUMENT_APP_STORE_MODULE_NAME,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/variables/variables";

.user-badge {
  background: $primary-color-3;
  color: white;
  margin-block: 3px;
}

.edit-icon {
  cursor: pointer;
}
</style>
